import { useState } from 'react';

import { useMutation } from 'react-query';
import { jobBoardFav } from 'services/recruitments';

import { getErrorText } from 'utils/geterrortext';
import { isUserLoggedIn } from 'utils/authentication';

import StarIcon from 'assets/icons/star-rate-empty.svg';
import TickIcon from 'assets/icons/tick-square.svg';

import classes from './JobBoardJobsCard.module.scss';

const JobBoardJobsCard = ({ jobData, active, toast, hasFavBtn = true }) => {
	const [isFav, setIsFav] = useState(() => {
		return jobData.is_favorite;
	});

	const { mutate: addToFav } = useMutation(jobBoardFav.POST, {
		onError: (error) => {
			toast(getErrorText(error), 'Alert');
			setIsFav(false);
		}
	});
	const { mutate: deleteFromFav } = useMutation(jobBoardFav.DELETE, {
		onError: (error) => {
			toast(getErrorText(error), 'Alert');
			setIsFav(true);
		}
	});

	const onFavClick = (e) => {
		e.stopPropagation();
		if (isUserLoggedIn()) {
			setIsFav((prevState) => !prevState);
			isFav ? deleteFromFav(jobData?.id) : addToFav(jobData?.id);
		} else {
			toast('Please login to add this job to you favorites');
		}
	};

	const getLanguagesDisplayValue = (languages = []) => {
		const isLongLanguages = languages.map((lang) => lang.name).join('').length > 20;
		if (isLongLanguages) {
			return languages.map((lng) => lng.iso.toUpperCase());
		} else {
			return languages.map((lng) => lng.name);
		}
	};

	return (
		<>
			<div
				className={`${classes['job-board-card-wrapper']} ${
					active ? classes['job-board-card-wrapper__active'] : ''
				}`}>
				<div
					className={`${classes['job-post-dashboard-card-title']} d-flex flex-row justify-content-between align-items-center mb-3`}>
					<div className="d-flex flex-row gap-2 align-items-center align-items-center">
						<div>
							{jobData.company.logo ? (
								<img src={jobData.company.logo} alt={jobData.company.name} />
							) : (
								<span>{jobData.company.name[0].toUpperCase()}</span>
							)}
						</div>
						<h5>{jobData.company.name}</h5>
					</div>
					{hasFavBtn && (
						<button
							className={`${classes['job-board-card-fav']} ${
								isFav ? classes['job-board-card-fav-enabled'] : ''
							}`}
							onClick={onFavClick}>
							<StarIcon />
						</button>
					)}
				</div>
				<h5>{jobData.title}</h5>
				{jobData?.company?.city ? <p>{jobData.company.city}</p> : ''}
				<div className={`${classes['job-board-card-body']} d-flex flex-column `}>
					<ul className="d-flex flex-column gap-3">
						<li>
							<TickIcon />
							{jobData.employment_type}
						</li>
						<li>
							<TickIcon />
							{jobData.location_type}
						</li>
						<li>
							<TickIcon />
							{jobData.contract_type}
						</li>
					</ul>
					<div className={`${classes['card-footer']} mt-3 d-flex flex-column flex-xl-row gap-3 justify-content-between align-items-start align-items-xl-center`}>
						{jobData?.languages && jobData?.languages.length > 0 ? (
							<div className="d-flex flex-row gap-1 flex-wrap">
								{getLanguagesDisplayValue(jobData.languages).map((lng) => (
									<span key={lng} className={classes['language']}>
										{lng}
									</span>
								))}
							</div>
						) : (
							''
						)}
						{jobData.published_at && (
							<p className={classes['published']}>
								<span>{jobData.published_at}</span>
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default JobBoardJobsCard;
